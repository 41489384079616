<template>
  <v-flex pb-5 mb-4>
    <v-data-table
      :headers="headers"
      :items="ventas"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      item-key="id"
      class="elevation-1 ventas-data-table"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Compras</v-toolbar-title>
        </v-toolbar>
      </template>
      <template v-slot:item.created_at="{ item }">{{ item.created_at_fmt }}</template>
      <template v-slot:item.metodo_pago_id="{ item }">{{ item.metodo_pago_descripcion }}</template>
      <template v-slot:item.action="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="viewDetails(item)"
          title="Ver Detalles"
          >
          mdi-eye
        </v-icon>

        <v-icon
          small
          class="mr-2"
          title="Imprimir Cupones"
          v-if="item.has_cupones_activos != '0' && item.estado_codigo == 'pagado'"
          @click="getCuponesActivosPorVenta(item.id)"
        >
          mdi-printer
        </v-icon>

        <v-btn
          small
          class="mr-2"
          title="Pagar"
          v-if="item.estado_codigo === 'esperando' && item.metodo_pago_codigo === 'transferencia_bancaria'"
          :href="item.print_datos_bancarios"
          target="_blank"
        >
          Pagar
        </v-btn>
      </template>
    </v-data-table>
  </v-flex>
</template>

<script>

export default {
  data: () => ({
    detailsDialog: false,
    detailsTab: null,
    loading: true,
    headers: [
      { text: 'Fecha', value: 'created_at', sortable: false },
      { text: 'Importe', value: 'importe_total_fmt', sortable: false },
      { text: 'Método de Pago', value: 'metodo_pago_id', sortable: false },
      { text: 'Estado', value: 'estado_descripcion', sortable: false},
      { text: 'Acciones', value: 'action', align: 'justify-center', sortable: false },
    ],
    estados: [],
    ventas: [],
    totalItems: 0,
    options: {},
    item: {},
  }),

  watch: {
    dialog (val) {
      val || this.close()
    },

    options: {
      handler () {
        this.query()
      },
      deep: true,
    },
  },

  methods: {
    query: function () {
      this.loading = true;

      let filters = {
        estado_id: null,
        search: '',
        per_page: this.options.itemsPerPage,
        page: this.options.page,
      };

      this.$http.get('cuenta/historial-de-compra', {params: filters})
      .then((response) => {
        this.ventas = response.data
        this.totalItems = parseInt(response.headers['pager-total'])
      })
      .catch(() => {
          //error.response.error.message
          this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        })
      .then(() => {
        this.loading = false;
      })
    },

    viewDetails: function (item) {
      this.$router.push({ name: 'historial-detalle', params: { id: item.id }})
    },

    getCuponesActivosPorVenta:function (venta_id) {
      this.$http.get('cuenta/compras/historial/cupones_activos/' + venta_id)
      .then((response) => {
        this.imprimirCupon(response.data)
      })
      .catch((error) => {
        switch (error.response.status) {
          case 401:
          break;
          case 404:
          this.$eventHub.$emit('snackbar-message', 'No hay ningún cupón activo para la venta seleccionada', 'error')
          break;
          default:
          this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
    },

    imprimirCupon:function (item) {
      var qrCodePrintWindow = window.open(false, "Cupón")
      let fecha_actual = new Date()

      let day = fecha_actual.getDate()
      let month = ('0' + (fecha_actual.getMonth() + 1)).slice(-2)
      let year = fecha_actual.getFullYear()

      fecha_actual = `${day}/${month}/${year}`

      qrCodePrintWindow.document.write('<div style="text-align:center;">')

      item.forEach((cupon) => {
        qrCodePrintWindow.document.write('<img src="'+cupon.codigoQR+'" alt="">')
        qrCodePrintWindow.document.write('<p><strong>Código: </strong>' + cupon.codigo + '</p>')
        qrCodePrintWindow.document.write('<p><strong>Detalle: </strong>' + cupon.detalle + '</p>')
        qrCodePrintWindow.document.write('<p><strong>Estado: </strong>' + cupon.estado_descripcion + '</p>')
        qrCodePrintWindow.document.write('<p><strong>Fecha: </strong>' + fecha_actual + '</p>')

        qrCodePrintWindow.document.write('<div style="border-bottom-style: dotted;"></div>')
      })

      qrCodePrintWindow.document.write('</div>')

      qrCodePrintWindow.onafterprint = qrCodePrintWindow.close

      setTimeout(function() {
        qrCodePrintWindow.print()
        qrCodePrintWindow.close()
      }, 100);
    },
  }
}
</script>

<style>
  .ventas-data-table td {
    white-space: nowrap;
  }
</style>
